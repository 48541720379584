html,
body {
  height: 100%;
  scroll-behavior: smooth;
}

input::-ms-clear,
input::-ms-reveal {
  display: none;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  -ms-overflow-style: scrollbar;
  -ms-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
  -webkit-text-size-adjust: 100%;
  font-family: sans-serif;
  font-size: 62.5%;
  line-height: 1.15;
}

@-ms-viewport {
  width: 100vw;
}

article,
aside,
dialog,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}

body {
  background-color: #2b2c2e;
  margin: 0;
}

[tabindex='-1']:focus {
  outline: none !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

.row {
  display: flex;
}

.column {
  flex: 50%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 500;
  margin-bottom: 1em;
  margin-top: 0;
}

p {
  margin-bottom: 1em;
  margin-top: 0;
}

abbr[title],
abbr[data-original-title] {
  border-bottom: 0;
  cursor: help;
  text-decoration: underline;
  text-decoration: underline dotted;
}

address {
  font-style: normal;
  line-height: inherit;
  margin-bottom: 1em;
}

input[type='text'],
input[type='password'],
input[type='number'],
textarea {
  -webkit-appearance: none;
}

ol,
ul,
dl {
  margin-bottom: 1em;
  margin-top: 0;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 500;
}

dd {
  margin-bottom: 0.5em;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1em;
}

dfn {
  font-style: italic;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  -webkit-text-decoration-skip: objects;
  background-color: transparent;
  cursor: pointer;
  outline: none;
  text-decoration: none;
  transition: color 0.3s;
}

a:active,
a:hover {
  outline: 0;
  text-decoration: none;
}

a[disabled] {
  cursor: not-allowed;
  pointer-events: none;
}

pre,
code,
kbd,
samp {
  font-size: 1em;
}

pre {
  margin-bottom: 1em;
  margin-top: 0;
  overflow: auto;
}

figure {
  margin: 0 0 1em;
}

img {
  border-style: none;
  vertical-align: middle;
}

svg:not(:root) {
  overflow: hidden;
}

a,
area,
button,
[role='button'],
input:not([type='range']),
label,
select,
summary,
textarea {
  touch-action: manipulation;
}

table {
  border-collapse: collapse;
}

caption {
  caption-side: bottom;
  padding-bottom: 0.3em;
  padding-top: 0.75em;
  text-align: left;
}

th {
  text-align: inherit;
}

input,
button,
select,
optgroup,
textarea {
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  margin: 0;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
html [type='button'],
[type='reset'],
[type='submit'] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

input[type='radio'],
input[type='checkbox'] {
  box-sizing: border-box;
  padding: 0;
}

input[type='date'],
input[type='time'],
input[type='datetime-local'],
input[type='month'] {
  -webkit-appearance: listbox;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  border: 0;
  margin: 0;
  min-width: 0;
  padding: 0;
}

legend {
  color: inherit;
  display: block;
  font-size: 1.5em;
  line-height: inherit;
  margin-bottom: 0.5em;
  max-width: 100%;
  padding: 0;
  white-space: normal;
  width: 100%;
}

progress {
  vertical-align: baseline;
}

[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  height: auto;
}

[type='search'] {
  -webkit-appearance: none;
  outline-offset: -2px;
}

[type='search']::-webkit-search-cancel-button,
[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

mark {
  padding: 0.2em;
}

.clearfix:after {
  clear: both;
  content: '';
  display: block;
}

html {
  font-size: 11px;
}

body {
  background-color: #f4f5f8;
  color: #2b2c2e;
  font-family: 'Nunito Sans', Helvetica, Arial, serif;
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 1.5;
  overflow-x: hidden;
}

/* Header */
.header {
  align-items: center;
  background: #ffffff;
  padding: 1rem 0;
  position: relative;
  width: 100%;
  z-index: 100;
  box-shadow: 0 0.375rem 0.625rem -0.375rem rgb(0 0 0 / 20%);
}

.header .container {
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 1280px;
  padding: 0 2rem;
}

.brand {
  left: -0.3rem;
  top: 0;
  opacity: 1;
  position: relative;
  transition: opacity 0.3s;
  display: flex;
  align-items: center;
}

.logo {
  display: block;
  height: 3.2rem;
  width: 17.6rem;
}

.menu-item {
  color: #a4a8af;
  display: inline-block;
  font-size: 1.4rem;
  font-weight: 700;
  margin: 0 0.7rem;
}

.menu-item:hover {
  color: #2b2c2e;
}

.login-btn {
  background: #ffffff;
  border: 1px solid #393a3d;
  border-radius: 0.5rem;
  color: #2b2c2e;
  display: inline-block;
  font-size: 1.4rem;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 1.45;
  margin: 0 1.5rem;
  padding: 0.6rem 2rem;
  text-align: center;
  transition: all 0.3s;
}

.login-btn:hover {
  background-color: #2b2c2e;
  color: #ffffff;
}

.sign-btn {
  background: #195190;
  border: 1px solid #195190;
  border-radius: 0.5rem;
  color: #ffffff;
  display: inline-block;
  font-size: 1.4rem;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 1.45;
  padding: 0.6rem 2rem;
  text-align: center;
}

.sign-btn:hover {
  background: #094180;
  color: #ffffff;
}

/* Main */

.container {
  margin: 0 auto;
  max-width: 1280px;
  padding: 0 2rem;
  position: relative;
  width: 100%;
}

h1, h2, h3 {
  color: #2b2c2e;
  font-weight: 900;
  letter-spacing: 0.025rem;
}

h1 {
  font-size: 5rem;
  line-height: 1.3;
  margin-bottom: 2rem;
}

h2 {
  font-size: 3.6rem;
  line-height: 1.3;
  margin-bottom: 2rem;
}

h3 {
  font-size: 1.6rem;
  margin-bottom: 1rem;
}

p {
  letter-spacing: 0.025rem;
}

b {
  font-weight: 700;
}

.btn {
  border-radius: 0.5rem;
  display: inline-block;
  font-size: 1.2rem;
  font-weight: 700;
  letter-spacing: 0.15rem;
  line-height: 1.45;
  padding: 1.7rem 5rem 1.8rem;
  text-align: center;
  text-transform: uppercase;
  cursor: wait;
}

.btn.primary {
  background: #195190;
  color: #ffffff;
  max-width: 28.2rem;
  transition: all 0.3s;
  width: 100%;

  &:disabled {
    opacity: 0.5;    
  }
}

.btn.primary:after {
  background: url('./images/arrow-right.svg') no-repeat center;
  background-size: contain;
  content: '';
  display: inline-block;
  height: 1.2rem;
  margin-left: 1rem;
  position: relative;
  top: 0.2rem;
  transition: all 0.3s;
  width: 1.6rem;
}

.btn.primary:hover {
  //box-shadow: 0.1rem 0.1rem 2.1rem rgba(25, 81, 144, 0.5);
  background: #094180;
  color: #ffffff;
  cursor: pointer;

  &:disabled {
    cursor: wait;
    opacity: 0.5;    
  }
}

.btn.primary:hover:after {
  transform: translateX(0.2em);
}

.btn.full-width,
.btn.primary.full-width {
  max-width: 100%;
  width: 100%;
}

small {
  display: block;
  font-size: 1.3rem;
  font-weight: 700;
  letter-spacing: 0.025rem;
}

.buttons-wrapper {
  display: flex;
}

.store-btn {
  display: block;
  height: 4rem;
  margin: 0.5rem 0;
  width: auto;
}

.store-btn:first-child {
  margin-right: 1rem;
}

.store-btn img {
  height: 100%;
  width: auto;
}

.hidden {
  display: none;
}

/* Banner */
.banner {
  min-height: 52rem;
  padding: 6.4rem 0 6rem;
  width: 100vw;
}

.banner .container {
  position: inherit;
}

.banner .wrapper {
  max-width: 42rem;
  text-align: left;
  width: 50%;
}

.banner h1 {
  line-height: 1.1;
  max-width: 54rem;
}

.banner p {
  margin: 0 0 2rem;
  max-width: 30rem;
  font-size: 1.6rem;
}

.banner small {
  margin-top: 2rem;
}

.banner-img {
  animation: show 1s cubic-bezier(0.36, 0.07, 0.19, 0.97) forwards;
  display: block;
  height: auto;
  left: 33%;
  max-height: 64rem;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
  z-index: -1;
}

.video-overlay-play-button {
  cursor: pointer;
  display: block;
  height: 10rem;
  left: 64%;
  position: absolute;
  top: 28rem;
  transition: all 0.1s linear;
  width: 10rem;
  z-index: 2;
}

.video-overlay-play-button:hover {
  transform: scale(1.1);
}

/* About */
.about {
  padding: 2rem 0 5rem;
  position: relative;
  width: 100%;
}

.about .wrapper {
  align-items: center;
  display: flex;
  flex-direction: row;
  height: 100%;
  justify-content: flex-start;
}

.about .img-wrapper {
  width: 50%;
}

.about .text-wrapper {
  margin: 2rem 5%;
  max-width: 36rem;
  width: 40%;
}

.about .text-wrapper h2 {
  line-height: 1.2;
  margin-bottom: 2.5rem;
}

.about .img-wrapper {
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;
}

.about .product-img {
  display: inline-block;
  position: relative;
  width: 45%;
}

.about .product-img:first-child {
  right: -1%;
}

.about .product-img:last-child {
  left: -1%;
}

.about .bottom {
  border-top: 1px solid #e3e5e8;
  display: flex;
  flex-direction: column;
  margin: 2.5rem auto 0;
  padding-top: 2.5rem;
  position: relative;
}

.about .bottom .wrap {
  display: flex;
  margin-bottom: 2rem;
}

.about .bottom .wrap:last-child {
  margin-bottom: 0;
}

.about .bottom h3 {
  padding-left: 3rem;
}

.about .bottom h3.padding-left {
  padding-left: 3.5rem;
}

.about .bottom .icon {
  height: 3rem;
  margin-right: 1rem;
  margin-top: -0.2em;
  position: absolute;
  width: 3rem;
}

.about .bottom .icon.small {
  height: 4rem;
  left: -1rem;
  margin-right: 0;
  margin-top: -0.9rem;
  width: 4rem;
}

.about .bottom p {
  font-size: 1.4rem;
}

.about .slick-slide {
  z-index: 1;
}

.about .slick-active {
  z-index: 100;
}

.about .slick-active .bottom .wrap {
  animation: show 1s cubic-bezier(0.36, 0.07, 0.19, 0.97) forwards;
  opacity: 0;
}

.about .slick-active .bottom .wrap:first-child {
  animation-delay: 0.5s;
}

.about .slick-active .bottom .wrap:nth-child(2) {
  animation-delay: 1.5s;
}

.about .slick-active .bottom .wrap:nth-child(3) {
  animation-delay: 2.5s;
}

/* Slider */
.slick-slider:focus,
.slick-list:focus,
.slick-track:focus,
.slick-active:focus,
.slick-slide:focus {
  outline: none !important;
}

.list {
  display: flex;
  justify-content: space-between;
  margin: 0 auto 4rem;
  padding-left: 0;
}

.list::before {
  content: "";
  height: 0.2rem;
  background: #e3e5e8;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
}

.list .slick-track {
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  transform: none !important;
  width: 100%;
}

.list .slick-slide {
  color: #a4a8af;
  cursor: pointer;
  font-size: 1.5rem;
  font-weight: 700;
  height: 5.2rem;
  line-height: 1.3;
  margin: 0 2.5rem;
  padding: 0;
  position: relative;
  text-align: center;
  transition: color 0.3s;
  width: auto !important;
}

.list .slick-slide:hover {
  color: #2b2c2e;
}

.list .slick-slide>div {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
}

.list .slick-slide::after {
  background: #195190;
  bottom: 0;
  content: '';
  height: 0.35rem;
  left: 0;
  position: absolute;
  right: 100%;
  transition: all 0.3s;
  border-radius: 600rem;
}

.list .slick-current {
  color: #2b2c2e;
}

.list .slick-current::after {
  left: 0;
  right: 0;
}

.slick-dots {
  display: block;
  left: 50%;
  list-style: none;
  margin: 0;
  margin-left: -75px;
  padding: 0;
  position: absolute;
  text-align: center;
  top: 62vw;
}

.slick-dots li {
  cursor: pointer;
  display: inline-block;
  height: 5px;
  margin: 0 10px;
  padding: 0;
  position: relative;
  width: 5px;
}

.slick-dots li button {
  background: transparent;
  border: 0;
  color: transparent;
  cursor: pointer;
  display: block;
  font-size: 0;
  height: 5px;
  line-height: 0;
  outline: none;
  padding: 5px 10px;
  width: 5px;
}

.slick-dots li button:before {
  background: #195190;
  border-radius: 100%;
  content: '';
  height: 5px;
  left: 0;
  opacity: 0.25;
  position: absolute;
  text-align: center;
  top: 0;
  width: 5px;
}

.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
  opacity: 1;
}

.slick-dots li.slick-active button:before {
  background: #195190;
  opacity: 0.75;
}

.badge {
  align-items: center;
  background: #ffb046;
  border-radius: 4rem;
  color: #ffffff;
  display: inline-flex;
  font-size: 1rem;
  height: 1.5rem;
  justify-content: center;
  letter-spacing: 0.035rem;
  line-height: 0.9rem;
  margin-left: 0.6rem;
  padding: 0.1rem 0.4rem;
  position: absolute;
  right: -2rem;
  top: 0;
}

.soon img {
  opacity: 0.2;
}

.coming-soon {
  background-image: url('./images/coming-soon.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% auto;
  display: block;
  height: 8.6rem;
  left: 50%;
  margin-left: -7.12rem;
  margin-top: -4.3rem;
  position: absolute;
  top: 50%;
  width: 14.25rem;
}

/* Benefits */
.benefits {
  background-color: #eff0f3;
  padding: 6rem 0 4rem;
}

.benefits p {
  margin-bottom: 0;
}

.benefits .wrap {
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 110rem;
}

.benefits .wrapper {
  color: #2b2c2e;
  margin: 0 auto 2rem;
  max-width: 23rem;
  text-align: center;
  width: 33%;
}

.benefits .img-wrapper {
  margin-bottom: 1.5rem;
  max-width: 100%;
}

.benefits .img-wrapper .icon {
  height: 6rem;
  max-width: 100%;
  width: 6rem;
}

.icon.slick-current .text-wrapper h2,
.icon.slick-current .text-wrapper p {
  animation: show 1s cubic-bezier(0.36, 0.07, 0.19, 0.97) forwards;
}

.icon.slick-current .product-img {
  animation: show 2s cubic-bezier(0.36, 0.07, 0.19, 0.97) forwards,
    move 2s cubic-bezier(0.36, 0.07, 0.19, 0.97) forwards;
  opacity: 0;
  transform: translateY(1rem);
}

.icon.slick-current .product-img:last-child {
  animation-delay: 0.5s;
}

@keyframes move {
  from {
    transform: translateY(1rem);
  }

  to {
    transform: translateY(0);
  }
}

@keyframes show {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Testimonials */
.testimonials {
  background-color: #195190;
  color: #ffffff;
  padding: 7rem 0;
  text-align: center;
}

.testimonials h2 {
  color: #ffffff;
  font-size: 3rem;
  text-align: center;
}

.testimonials h2:before {
  background-image: url('./images/quote-icon.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 8rem auto;
  content: '';
  display: inline-block;
  height: 8rem;
  left: 50%;
  margin-left: -6.5rem;
  opacity: 0.1;
  position: absolute;
  top: -3rem;
  width: 13rem;
}

.testimonials .wrap {
  margin: 0 auto;
  max-width: 78rem;
}

.testimonials p {
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 1.5;
  margin: 0 auto;
  max-width: 48rem;
}

/* Button wrapper */
.btn-wrapper {
  overflow: hidden;
  padding: 5rem 0 0;
  position: relative;
  text-align: left;
}

.btn-wrapper .wrapper {
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 100rem;
}

.btn-wrapper h1 {
  line-height: 1.1;
  margin-right: 5%;
}

.btn-wrapper small {
  margin-top: 1rem;
}

/* Footer */
.footer {
  background-color: #eff0f3;
  margin-top: 5rem;
  padding: 2rem 0;
  position: relative;
  text-align: center;
  z-index: 1;
}

.footer p {
  color: #2b2c2e;
  display: inline-block;
  font-size: 1.3rem;
  font-weight: 600;
  margin: 0 auto;
}

/* 404 */
.error-page .banner {
  min-height: calc(100vh - 16.8rem);
}

.error-page .banner .container {
  align-items: center;
  display: flex;
  justify-content: center;
  min-height: 44rem;
}

.error-page .banner .wrapper {
  margin: 2rem 4rem;
  padding: 0;
}

.error-page h2 {
  margin-bottom: 1rem;
}

.error-page .btn {
  margin-bottom: 0;
}

.error-page .error-img {
  margin: 3rem 4rem 0;
  max-width: 28rem;
}

/* Single page */
.single-page {
  padding-top: 4rem;
}

.single-page .container {
  max-width: 100rem;
  padding-bottom: 5rem;
}

.single-page h2 {
  margin-bottom: 2rem;
  margin-top: 4rem;
}

/* Form */
.form-wrapper {
  //box-shadow: 0 0 2rem rgba(70, 102, 139, 0.2);
  background: #ffffff;
  border-radius: 0.5rem;
  margin: 6rem auto;
  max-width: 100%;
  padding: 3.5rem 5rem;
  width: 47rem;
  box-shadow: 0 0.125rem 0.5rem 0 rgb(0 0 0 / 20%);
}

.form-wrapper h2 {
  font-size: 3rem;
}

.form-wrapper .fieldset {
  margin-bottom: 1.5rem;
  margin-right: 0;
  max-width: 100%;
  position: relative;
}

.form-wrapper .two-col {
  display: flex;
  justify-content: space-between;
  margin: 1rem 0;
}

.form-wrapper .two-col .fieldset {
  width: 48%;
}

.form-wrapper label {
  display: inline-block;
  font-size: 1.4rem;
  font-weight: 400;
  margin-bottom: 0.5rem;

  &.error {
    color: red;
  }
}

.form-wrapper input[type="text"],
.form-wrapper input[type="password"],
.form-wrapper input[type="email"],
.form-wrapper input[type="date"],
.form-wrapper input[type="tel"] {
  background-color: #eeeff2;
  border: none;
  border-radius: 0.4rem;
  box-shadow: none;
  display: block;
  font-size: 1.4rem;
  height: 4rem;
  letter-spacing: normal;
  padding: 0 1rem;
  text-transform: none;
  width: 100%;

  &.error {
    background-color: rgb(252, 229, 229);
  }
}

.fieldset input[type="password"] {
  padding-right: 3em;
}

.input-icon {
  background-size: 100% auto;
  bottom: 0.7em;
  content: "";
  cursor: pointer;
  display: block;
  height: 17px;
  position: absolute;
  right: 1em;
  width: 17px;
}

.eye {
  background: url("./images/eye.svg") no-repeat center;
}

.eye-off {
  background: url("./images/eye-off.svg") no-repeat center;
}

.fieldset input[type="file"] {
  border-radius: 0;
  display: block;
  letter-spacing: normal;
  margin-bottom: 3rem;
  padding: 0;
  text-transform: none;
}

.form-wrapper .btns-wrapper {
  display: flex;
  justify-content: space-between;
}

.form-wrapper .link {
  color: #195190;
  font-size: 16px;
  font-weight: 800;
}

.form-wrapper .link:hover {
  text-decoration: underline;
}

.form-wrapper .notes {
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 1.3;
  margin: 3rem auto 1rem;
  max-width: 90%;
  text-align: center;
}

.center {
  align-items: center;
  display: flex;
  justify-content: center;
}

/* Pricing */
.pricing .container {
  margin: 0 auto;
  max-width: 70rem;
}

.pricing h1 {
  font-size: 3.6rem;
  line-height: 1.1;
  text-align: center;
}

h1 span {
  color: #393a3d !important;
  display: block;
  font-size: 1rem;
  font-weight: 700;
  letter-spacing: 0.1rem;
  margin: 1rem auto 2rem;
  text-transform: uppercase;
}

.pricing-wrapper {
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
  margin-top: 5rem;
}

.pricing-block {
  box-shadow: 0 0.125rem 0.5rem 0 rgb(0 0 0 / 20%);
  background: #ffffff;
  border-radius: 0.5rem;
  padding: 3rem 3rem;
  text-align: center;
  width: 48%;
}

.pricing-block h2 {
  font-size: 2.4rem;
  letter-spacing: 0.025rem;
  margin-bottom: 1rem;
  margin-top: 0;
  font-weight: 800;
}

.pricing-block h3 {
  color: #2b2c2e;
  font-size: 3rem;
  margin-bottom: 2rem;
}

.pricing p {
  margin: 0 auto 2.5rem;
  max-width: 23rem;
}

.pricing-block hr {
  background-color: #e3e5e8;
  border: 0;
  display: block;
  height: 1px;
  margin: 3rem 0;
  position: relative;
}

.pricing-block span {
  display: block;
  font-size: 1.5rem;
  text-align: left;
  width: 100%;
}

.pricing-block ul {
  font-weight: 400;
  list-style: none;
  margin: 2rem 0;
  padding: 0;
}

.pricing-block li {
  padding: 0.5rem 0;
}

.pricing-block li:before {
  background: url('./images/icon-check.svg') no-repeat center;
  background-size: contain;
  content: '';
  display: inline-block;
  height: 1.2rem;
  margin-left: 1rem;
  position: relative;
  top: 0.2rem;
  transition: all 0.3s;
  width: 1.6rem;
  left: -0.8rem;
}

.error-wrapper {
  border-radius: 0.5rem;
  padding: 1rem;
  background: rgb(248, 186, 186);
  margin-bottom: 1rem;
  box-shadow: 0 0.125rem 0.5rem 0 rgb(0 0 0 / 20%);
  font-size: 1.4rem;
}

.error {
  color: red;
}

/* Media */
@media (max-width: 1300px) {
  .banner-img {
    max-height: 63rem;
  }

  .btn-wrapper h1 {
    max-width: 70rem;
  }

  .pricing h1 {
    margin-left: auto;
    margin-right: auto;
    max-width: 90%;
  }
}

@media (max-width: 1200px) {
  .slick-slider .slick-track {
    justify-content: space-between;
  }

  .list .slick-slide {
    height: 7rem;
    max-width: 90px;
    margin: 0;
  }

  .list .badge {
    font-size: 11px;
    height: 14px;
    line-height: 12px;
    right: 0;
    width: 34px;
  }
}

@media (max-width: 1024px) {
  h1 {
    font-size: 4.5rem;
  }

  h1 span {
    font-size: 12px;
  }

  h2,
  .pricing h1 {
    font-size: 3.2rem;
  }

  p,
  h3,
  .banner p,
  .about .text-wrapper p,
  .benefits .title {
    font-size: 16px;
  }

  .btn.primary {
    font-size: 12px;
    max-width: 282px;
    padding: 1.6em 1.5em;
  }

  small,
  .banner small,
  .footer p {
    font-size: 14px;
  }

  .banner {
    min-height: 50rem;
    padding: 5rem 0 2rem;
  }

  .banner .wrapper {
    max-width: 38rem;
    text-align: left;
    width: 100%;
  }

  .banner h1,
  .banner p {
    margin-left: 0;
    margin-right: 0;
  }

  .store-btn {
    height: 40px;
  }

  .banner-img {
    max-height: 58rem;
  }

  .video-overlay-play-button {
    height: 9rem;
    left: 64%;
    top: 26rem;
    width: 9rem;
  }

  .list div {
    font-size: 15px;
  }

  .about {
    padding: 2rem 0 4rem;
  }

  .about .bottom p {
    font-size: 15px;
  }

  .benefits {
    padding: 5rem 0 3rem;
  }

  .benefits .wrapper {
    margin: 0 2rem 2rem;
    max-width: 23rem;
  }

  .testimonials {
    padding: 5rem 0;
  }

  .testimonials p {
    font-size: 1.8rem;
  }

  .btn-wrapper h1 {
    font-size: 4.45vw;
    max-width: 35rem;
  }

  .footer {
    margin-top: 3rem;
  }

  .error-page .banner {
    min-height: calc(100vh - 14.8rem);
  }

  .error-page .error-img {
    margin: 3rem 2rem 0;
  }

  .pricing h1 {
    margin-left: auto;
    margin-right: auto;
    max-width: 40rem;
  }

  .pricing h1 span {
    margin-top: 0;
  }
}

@media (max-width: 800px) {
  .header {
    padding: 15px 0;
  }

  h1 {
    font-size: 42px;
  }

  h2,
  .form-wrapper h2 {
    font-size: 28px;
  }

  .btn.primary {
    max-width: 100%;
    padding: 1.6em 1.5em;
  }

  .btn.primary:after {
    height: 15px;
    margin-left: 2rem;
    top: 0.3rem;
    width: 15px;
  }

  .banner {
    margin: 0;
    padding: 0;
    position: relative;
  }

  .banner .wrapper {
    max-width: 100%;
    padding-top: 60vw;
    position: relative;
    z-index: 1;
  }

  .banner h1,
  .banner p {
    max-width: 100%;
  }

  .banner h1 {
    line-height: 1.2;
  }

  .banner-img {
    left: 0;
    margin: 0;
    max-height: 75vw;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
  }

  .video-overlay-play-button {
    left: auto;
    margin-right: -45px;
    margin-top: -45px;
    right: 50%;
    top: 27vw;
  }

  .slick-slider.list {
    display: none;
  }

  .about {
    background: #ffffff;
    margin-top: 4rem;
    padding: 4rem 0;
  }

  .about:before {
    background-position: -10% 0;
    background-size: 50vw auto;
    z-index: 0;
  }

  .about .wrapper {
    display: block;
  }

  .about .img-wrapper,
  .about .text-wrapper {
    margin: 0 auto;
    max-width: 100%;
    width: 100%;
  }

  .about .img-wrapper {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .about .product-img {
    display: inline-block;
    left: auto;
    max-height: 60vw;
    max-width: none;
    width: auto;
  }

  .about .product-img:last-child {
    left: auto;
    position: relative;
  }

  .about .text-wrapper {
    margin-top: 7rem;
  }

  .benefits {
    padding: 3rem 0;
    text-align: left;
  }

  .benefits .wrap {
    flex-wrap: wrap;
  }

  .benefits .wrapper {
    margin: 2rem 0;
    max-width: 100%;
    text-align: left;
    width: 100%;
  }

  .benefits .title,
  .benefits h2 {
    max-width: 100%;
    text-align: left;
  }

  .benefits .img-wrapper .icon {
    height: 50px;
    width: 50px;
  }

  .testimonials .wrap,
  .testimonials p {
    font-size: 16px;
    max-width: 45rem;
    width: 100%;
  }

  .testimonials p {
    font-size: 18px;
  }

  .testimonials h2 {
    font-size: 28px;
  }

  .btn-wrapper {
    padding: 4rem 0 0;
  }

  .btn-wrapper h1 {
    font-size: 36px;
    max-width: 100%;
  }

  .footer {
    margin-top: 4rem;
    padding: 2rem 0;
  }

  .error-page .error-img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 18rem;
  }

  .error-page .banner {
    min-height: calc(100vh - 19.5rem);
  }

  .error-page .banner .container {
    display: block;
  }

  .error-page .banner .wrapper {
    float: none;
    margin-left: 0;
  }

  .pricing-wrapper {
    flex-wrap: wrap;
  }

  .pricing .container {
    padding-bottom: 0;
  }

  .pricing p {
    height: auto;
    margin-bottom: 1rem;
  }

  .pricing-block {
    margin: 0 auto 3rem;
    max-width: 385px;
    width: 100%;
  }

  .pricing-block h2 {
    font-size: 28px;
  }

  .form-wrapper {
    margin-bottom: 0;
    margin-top: 4rem;
    padding: 3.5rem;
  }

  .form-wrapper label,
  .form-wrapper .notes {
    font-size: 15px;
  }
}

@media (max-width: 700px) {
  .sign-btn {
    display: none;
  }

  .login-btn {
    margin-right: 0;
  }

  .btn-wrapper .wrapper {
    flex-wrap: wrap;
  }

  .btn-wrapper h1 {
    max-width: 100%;
    width: 100%;
  }

  .btn-wrapper .actions-wrapper {
    width: 100%;
  }
}

@media (max-width: 600px) {
  h1 {
    font-size: 36px;
  }

  h2,
  .form-wrapper h2 {
    font-size: 24px;
  }

  .about .text-wrapper h3,
  .banner p,
  .benefits .title {
    font-size: 16px;
  }

  .testimonials .wrap,
  .testimonials p {
    max-width: 40rem;
  }

  .testimonials h2 {
    font-size: 24px;
  }

  .testimonials p {
    font-size: 16px;
  }

  .btn-wrapper h1 {
    font-size: 32px;
  }

  .pricing h1 {
    max-width: 30rem;
  }

  .pricing-block h2 {
    font-size: 24px;
  }

  .pricing-wrapper {
    margin-top: 3rem;
  }
}

@media (max-width: 480px) {
  .brand {
    left: 0;
  }

  .logo {
    height: 7vw;
    width: 37.5vw;
  }

  .menu-item {
    font-size: 14px;
  }

  .login-btn {
    font-size: 14px;
    margin-left: 0.5rem;
  }
}

@media (max-width: 360px) {

  .buttons-wrapper {
    display: block;
  }

  .store-btn {
    height: 46px;
    margin-right: 0;
    margin-top: 1rem;
  }

  .btn.primary:after {
    display: none;
  }

  .form-wrapper {
    padding: 3.5rem 2rem;
  }

  .form-wrapper .two-col {
    flex-wrap: wrap;
  }

  .form-wrapper .two-col .fieldset {
    width: 100%;
  }
}

.selectedHeader {
  color: #195190;
}

.notSelectedHeader {
  color: #adaeb4;
}
