/* Modal */
.modal {
    align-items: center;
    background: rgba(246, 247, 250, 0.8);
    bottom: 0;
    display: flex;
    height: 100vh;
    justify-content: center;
    left: 0;
    opacity: 0;
    position: fixed;
    transition: 0.3s ease;
    visibility: hidden;
    width: 100vw;
    z-index: 100;
}

.modal.show {
    opacity: 1;
    visibility: visible;
}
